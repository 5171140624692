import { lighten } from 'polished'

const colors = {
  primary: '#DCDCDC', // Color for buttons or links
  primaryLight: lighten(0.05, '#0f0f0f'),
  bg: '#000', // Background color
  grey: {
    dark: '#fff',
    default: '#fff',
    light: '#fff',
    ultraLight: '#fff',
  },
  white: '#fff',
}

const transitions = {
  normal: '0.5s',
}

const fontSize = {
  small: '0.9rem',
}

const fontFamily = {
  serif: `'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', serif`,
  sansSerif: `'Courier New', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
}

const breakpoints = {
  tablet: '1200px',
  phone: '600px',
}

const theme = {
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: '1000px',
  baseFontSize: '18px',
}

export default theme
